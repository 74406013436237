<template>
	 <div class="container" v-if="!UserStore.clientwidth">
		 <el-breadcrumb separator="/">
		   <el-breadcrumb-item v-for="(item,i) in levelList" :key='item.path'>
			   <span v-if="item.redirect=='noredirect'|| i == levelList.length - 1">{{$t(item.meta.title)}}</span>
			   <router-link v-else :to='item.redirect||item.path'>{{$t(item.meta.title)}}</router-link>
			</el-breadcrumb-item>
		 </el-breadcrumb>
	 </div>
</template>

<script setup>
	import * as pathToRegexp from 'path-to-regexp'
	import { onMounted , reactive , watch , toRefs} from 'vue';
	import { useRouter , useRoute } from "vue-router"
	import { themeStore, UserInfoStore } from "@/stores/index.js";
	const UserStore = UserInfoStore();
	defineOptions({
		name:'Breadcrumb'
	})
	const route=useRoute()
	const router=useRouter()
	const state=reactive({
		levelList:[]
	})
	// 过滤有参数的参数
	const filterQuery=(params,query)=>{
		for(let v in params){
			return params
		}
		return query
	}
	const getBreadcrumb=()=>{
		console.log(route.matched,"matched")
		const {params,query} =route 
		let matched= route.matched.filter((v,i)=>{
			if (v.name) {
			  v.path = pathToRegexp.compile(v.path)(params)
			  return true
			}
		})
		const first = matched[0]
		if (first && first.name == 'index') {
		  matched = [matched[1]]
		}
		state.levelList = matched
		// console.log(matched,'-------matched')
	}
	watch(()=>router.currentRoute.value,(newval,oldval)=>{
		getBreadcrumb()
	})
	const { levelList } = toRefs(state)
	onMounted(()=>{
		getBreadcrumb()
	})
</script>
<script>
	export default {
	  name: 'Breadcrumb',
	}
</script>

<style scoped lang='scss'>
	.container{
		margin-left: 20px;
		min-width: 200px;
	}
</style>