import router from "@/router/index.js"
import NProgress from "nprogress"; // 导入 nprogress模块
import "nprogress/nprogress.css"; // 导入样式，否则看不到效果
NProgress.configure({ showSpinner: false }); // 显示右上角螺旋加载提示
import { getToken } from "@/utils/auth.js"
import { toRaw } from "vue"
import config from "@/config"
import { UserInfoStore, GenerateRoutesStore,themeStore } from '@/stores/index.js'
const { whiteList } = config
router.beforeEach((to, from, next) => {
	const theme_store = themeStore();
	if(theme_store.theme.showProgressBar)NProgress.start();
	// 必须在拦截函数中调用方法 否则pinia还是未初始化状态
	const UserStore = UserInfoStore()
	const useGenerateRoutes = GenerateRoutesStore()
	if (getToken('token')) {
		if (to.path == '/Login') {
			next()
			NProgress.done(); //完成进度条
		} else {
			if (useGenerateRoutes.addRouters.length == 0) {
				// 设置项目主题
				theme_store.setTheme();
				// 获取用户信息 路由权限
				UserStore.GetUserInfo().then((res) => {
					// console.log(res, '获取用户权限')
					let perm = res
					// 通过权限过滤路由
					useGenerateRoutes.GenerateRoutes(perm).then(routes => {
						//循环添加路由信息
						useGenerateRoutes.routers.forEach((v, i) => {
							// console.log(v)
							router.addRoute(toRaw(v))
						})
						// console.log(useGenerateRoutes.routers)
						next({
							...to,
							replace: true
						}) // hack方法 确保addRoute已完成 ,set the replace: true so the navigation will not leave a history record
						NProgress.done()
					})
				}).catch((err) => {
					console.log(err)
					UserStore.FedLogOut()
				})
				console.log(router.getRoutes())
			} else {
				// 可以动态判断权限 无权限跳转到404, 获取当前账号权限比对路由中的权限，无权限跳转404页面
				next()
			}
		}
	} else {
		theme_store.setTheme();
		if (whiteList.indexOf(to.path) > -1) {
			next()
			NProgress.done();
		} else {
			next(`/Login?redirect=${to.path}`)
			NProgress.done()
		}
	}
})
router.afterEach(() => {
	NProgress.done();
});