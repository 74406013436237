import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { install } from "./utils/baseMessage.js";
import init from "@/components/components.js";
const pinia = createPinia();
import ElementPlus from "element-plus";
// 引入element-plus 图标
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
//导入iconfont图标
import "./styles/iconfont/iconfont.css";
import "element-plus/dist/index.css";
//导入路由守卫
import "./permission.js";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import i18n from "@/i18n/index.js";
import initDirective  from  "./directive/index.js"
import Particles from "vue3-particles";
import print from "vue3-print-nb";
import Vue3NumberScroll from 'vue3-number-scroll' 
import countTo from 'vue3-count-to' 
import "@/utils/echarts-tooltip-carousel.js";
const app = createApp(App);
// 注册国际化
app.use(i18n);
// 注册全局组件
init(app);
// 注入全局Message
install(app);
// 全局自定义指令
initDirective(app)
// 注册pinia
app.use(pinia);
// element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app
  .use(Particles)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(Vue3NumberScroll)
  .use(countTo)
  .use(print)
  .use(router)
  .mount("#app");
