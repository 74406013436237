<template>
	<div class="el-drop">
		<el-dropdown trigger="click">
		        <span class="el-dropdown-link">
					<!-- <el-icon size='18' color='#666'><UserFilled /></el-icon> -->
						<el-avatar   :src="Path('user.gif')" shape="circle"/>
						<span>{{ UserStore.user.username || '游客' }}</span>
					<el-icon class="el-icon--right"><arrow-down /></el-icon>
		        </span>
		        <template #dropdown>
		          <el-dropdown-menu>
					  <router-link to='/setting/center/index'>
					  	   <el-dropdown-item icon="User">个人中心</el-dropdown-item>
					  </router-link>
					  <router-link to='/EditPassWord'>
						   <el-dropdown-item icon="Edit">修改密码</el-dropdown-item>
					  </router-link>
		            <el-dropdown-item icon="SwitchButton" @click="LogOut">退出登录</el-dropdown-item>
		          </el-dropdown-menu>
		        </template>
		</el-dropdown>
	</div>
</template>

<script setup>
	import {UserInfoStore} from "@/stores/index.js"
	defineOptions({
		name:'Dropdown'

	})
	const Path =(name)=> new URL(`../../assets/${name}`,import.meta.url).href
	const UserStore=UserInfoStore()
	const props=defineProps({
		
	})
	const LogOut=()=>{
		UserStore.FedLogOut()
	}
</script>

<style scoped lang='scss'>
	.el-dropdown-link{
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.el-dropdown-link span{
		margin: 0 0 0 6px;
	}
	.el-drop{
		margin-left: 20px;
	}
	.el-dropdown-menu a{
		text-decoration: none;
	}
</style>