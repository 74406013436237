<template>
  <div class="lock">
    <div class="password">
      <div class="avatar">
        <el-avatar
          :size="180"
          :src="Path('user.gif')"
        />
        <el-avatar :size="180" v-if="false"> user </el-avatar>
      </div>
      <div class="title">屏幕已锁定</div>
      <div class="title">
        <el-button icon="Lock" circle />
      </div>
      <div class="btn">
        <el-input
          style="width: 360px"
          v-model="state.password"
          type="password"
          size="large"
          :autofocus="true"
          show-password
          maxlength="18"
          @keyup.enter="handleLock"
        >
          <template #suffix>
            <el-button
              type="primary"
              icon="Lock"
              size="large"
              style="border-radius: 0; outline: none"
              @click="handleLock"
              >解锁</el-button
            >
          </template>
        </el-input>
      </div>
    </div>
  </div>
</template>
<script setup>
defineOptions({
  name: "Lock",
});
import { reactive, toRefs, onBeforeMount, onMounted } from "vue";
import { UserInfoStore } from "@/stores/index.js";
import { SetStorage } from "@/utils/auth.js";
const Path =(name)=> new URL(`../../assets/${name}`,import.meta.url).href
const UserStore = UserInfoStore();
const state = reactive({
  password: "123456",
});
const handleLock = () => {
  UserStore.$patch({
    Lockvisible: false,
  });
  SetStorage("Lockvisible", false);
};
onMounted(() => {});
</script>
<style lang="scss" scoped>
.lock {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999999999999;
  backdrop-filter: blur(10px);
  background: rgba($color: #fff, $alpha: 0.5);
  display: flex;
  .password {
    border-radius: 10px;
    padding: 40px 80px;
    margin: auto;
    .btn {
      display: flex;
      align-items: center;
    }
    .avatar {
      text-align: center;
    }
    .title {
      text-align: center;
      margin: 20px;
    }
    ::v-deep(.el-input__wrapper) {
      padding-right: 0;
    }
    ::v-deep(.el-input .el-input__icon) {
      position: absolute;
      right: 100px;
    }
  }
}
</style>
