<template>
    <el-scrollbar>
      <el-tabs
        v-model="TabsValue"
        class="demo-tabs"
        type="card"
        tab-position="bottom"
        closable
        @tab-remove="removeTab"
      >
        <template v-for="(item, i) in TagViewsList">
          <el-tab-pane
            :key="item.path"
            :name="item.path.toString()"
            v-if="!item.meta.tabHidden"
          >
            <template #label>
              <span
                @click="handleTags(item, i)"
                style="display: flex; align-items: center"
              >
                <el-icon v-if="item.meta.icon && theme_store.theme.showTabsIcon"
                  ><component :is="item.meta.icon"></component>
                </el-icon>
                <span
                  class="tag"
                  ref="tab"
                  @contextmenu.prevent="openMenu($event, i, item)"
                >
                  {{ $t(item.meta.title) }}
                </span>
              </span>
            </template>
          </el-tab-pane>
        </template>
      </el-tabs>
    </el-scrollbar>
  <Drop
    v-show="visible"
    @refresh="refresh"
    @close="close"
    @closeAll="closeAll"
    @closeOther="closeOther"
    @closeLeft="closeLeft"
    @closeRight="closeRight"
    ref="drop"
    :left="left"
    :top="top"
  />
</template>

<script setup>
defineOptions({
  name:'Card'
})
import { VueDraggable, vDraggable } from "vue-draggable-plus";
import config from "@/config/index.js";
import { storeToRefs } from "pinia";
import { reactive, toRefs, watch, ref, onMounted, computed } from "vue";
import { TagviewsStore, themeStore } from "@/stores/index.js";
import Drop from "@/views/Layout/TagsView/component/Drop.vue";
const { theme_config } = config;
const useViewStore = TagviewsStore();
const theme_store = themeStore();
const {
  TagViewsList,
  cacheNameViews,
  TagViews,
  delcacheNameViews,
  KeyValue,
  routerKeyValue,
} = storeToRefs(useViewStore);
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const state = reactive({
  TabsValue: "/home",
  left: "0",
  top: "0",
  visible: false,
  value: null,
});
const tab = ref(null);
const drop = ref(null);
const removeTab = v => {
  let delview = "";
  for (let i in TagViews.value) {
    if (TagViews.value[i].path == v) {
      delview = TagViews.value.splice(i, 1);
      break;
    }
  }
  if (router.currentRoute.value.path == v) {
    let lastView = TagViews.value.slice(-1)[0];
    if (lastView) {
      router.push(lastView);
    } else {
      router.push("/");
    }
  }
  useViewStore.delcacheNameViews(delview[0]);
};
const handleTags = (v, i) => {
  console.log(i);
  router.push({
    path: v.path,
    query: v.query,
  });
};
const addTag = () => {
  let route = router.currentRoute.value;
  state.TabsValue = route.path;
  useViewStore.addTags(route);
  console.log(cacheNameViews);
  console.log(TagViews);
  routerKeyValue.value = route.path;
};
const handleClose = () => {
  state.visible = false;
  drop.value.handleClose();
};
const openMenu = (e, i, item) => {
  state.visible = true;
  state.value = item;
  state.left = e.clientX + "px";
  state.top = tab.value[i].getBoundingClientRect().top + 20 + "px";
  drop.value.handleDropOpen();
};
const refresh = () => {
  useViewStore.refreshCurrentView(state.value);
};
const close = v => {
  useViewStore.delTagViews(state.value);
  useViewStore.delcacheNameViews(state.value);
};
const closeAll = v => {
  useViewStore.delTagViewsAll();
};
const closeOther = v => {
  router.push(state.value);
  useViewStore.delTagViewsOther(state.value);
  useViewStore.delCacheNameViewsOther(state.value);
};
const closeLeft = v => {
  useViewStore.delTagViewsLeft(state.value).then(res => {
    console.log(res);
    const list = res;
    list.forEach((v, i) => {
      if (v.path == router.currentRoute.value.path) {
        router.push(state.value);
      }
    });
  });
};
const closeRight = v => {
  useViewStore.delTagViewsRight(state.value).then(res => {
    console.log(res);
    const list = res;
    list.forEach((v, i) => {
      if (v.path == router.currentRoute.value.path) {
        router.push(state.value);
      }
    });
  });
};
watch(
  () => router.currentRoute.value,
  (newval, oldval) => {
    addTag();
  }
);
const { TabsValue, left, top, visible } = toRefs(state);
onMounted(() => {
  addTag();
});
</script>

<style scoped lang="scss">
::v-deep(.el-tabs__nav-wrap::after) {
  background: none;
}
.tag {
  display: inline-block;
  height: 100%;
  line-height: 40px;
}
.el-icon {
  margin: 0 4px 0 0;
}
</style>
