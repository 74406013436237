<template>
  <Drawer title="主题" v-model:visible="props.visible" v-model:size="state.size" @handleClose="handleClose">
    <el-form :model="theme_store.theme" label-width="130px" label-position="left">
      <el-form-item label="主题">
        <el-select
          v-model="theme_store.theme.themeName"
          @change="themeChange"
        >
          <el-option
            v-for="item in state.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="菜单宽度">
        <!-- <el-input v-model="theme_store.theme.menuWidth" clearable @blur="themeChange"> </el-input> -->
          <el-select
              v-model="theme_store.theme.menuWidth"
              @change="themeChange"
          >
            <el-option
              v-for="item in state.WidthList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
        </el-select>
      </el-form-item>
      <el-form-item label="头部固定">
        <el-switch v-model="theme_store.theme.fixedHeader" />
      </el-form-item>
      <el-form-item label="进度条">
        <el-switch v-model="theme_store.theme.showProgressBar" />
      </el-form-item>
      <el-form-item label="标签">
        <el-switch v-model="theme_store.theme.showTabs" />
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex; align-items: center">
            <span style="margin: 0 4px 0 0">标签风格</span>
            <el-tooltip
              effect="dark"
              content="标签开启时生效"
              placement="top"
            >
              <el-icon pointer><QuestionFilled /></el-icon>
            </el-tooltip>
          </div>
        </template>
        <el-select
          v-model="theme_store.theme.tabsBarStyle"
          :disabled="!theme_store.theme.showTabs"
        >
          <el-option
            v-for="item in state.tabsBarList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex; align-items: center">
            <span style="margin: 0 4px 0 0">标签图标</span>
            <el-tooltip
              effect="dark"
              content="标签开启时生效"
              placement="top"
            >
              <el-icon pointer><QuestionFilled /></el-icon>
            </el-tooltip>
          </div>
        </template>
        <el-switch v-model="theme_store.theme.showTabsIcon"  :disabled="!theme_store.theme.showTabs" />
      </el-form-item>
      <el-form-item label="国际化">
        <el-switch v-model="theme_store.theme.showLanguage" />
      </el-form-item>
      <el-form-item label="刷新">
        <el-switch v-model="theme_store.theme.showRefresh" />
      </el-form-item>
      <el-form-item label="搜索">
        <el-switch v-model="theme_store.theme.showSearch" />
      </el-form-item>
      <el-form-item label="通知">
        <el-switch v-model="theme_store.theme.showNotice" />
      </el-form-item>
      <el-form-item label="全屏">
        <el-switch v-model="theme_store.theme.showFullScreen" />
      </el-form-item>
      <el-form-item label="锁屏">
        <el-switch v-model="theme_store.theme.showLock" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="resetDefault">恢复默认</el-button>
        <el-button type="primary" @click="confirmClick">保存</el-button>
      </div>
    </template>
  </Drawer>
</template>

<script setup>
import { reactive, toRefs, onBeforeMount, onMounted,watch } from "vue";
defineExpose({
  name: "Theme_Drawer",
});
import { themeStore } from "@/stores/index.js";
import config from '@/config/index.js'
import {removeStorage} from "@/utils/auth.js"
const {theme_config}=config
console.log(theme_config)
const theme_store = themeStore();
const props=defineProps({
  visible:{
    type:Boolean
  }
})
const emit= defineEmits(['update:visible'])
const state = reactive({
  options:[
    {
      value:'blue-black',
      label:'蓝黑'
    },
    {
      value:'blue-white',
      label:'蓝白'
    },
    {
      value:'green-black',
      label:'绿黑'
    },
    {
      value:'green-white',
      label:'绿白'
    },
    {
      value:'purple-black',
      label:'紫黑'
    },
    {
      value:'purple-white',
      label:'紫白'
    },
    {
      value:'red-black',
      label:'红黑'
    },
    {
      value:'red-white',
      label:'红白'
    }
  ],
  tabsBarList:[
      {
        value:'CardTags',
        label:'常规'
      },
      {
        value:'Card',
        label:'卡片'
      },{
        value:'MoveTags',
        label:'滑动'
      }
  ],
  WidthList:[
    {
      value:'244px',
      label:"244px"
    },
    {
      value:'255px',
      label:"255px"
    },
    {
      value:'266px',
      label:"266px"
    },
    {
      value:'277px',
      label:"277px"
    },
    {
      value:'288px',
      label:"288px"
    }
  ],
  size:300,
});
const resetDefault = () => {
  theme_store.$patch((state)=>{
    state.theme={...theme_config}
  })
  theme_store.setTheme()
  removeStorage('theme')
  handleClose(false)
};
const confirmClick = () => {
  theme_store.saveTheme()
  handleClose(false)
};
const handleClose=(v)=>{
  emit('update:visible',v)
}
const themeChange = () =>{
  theme_store.setTheme()
}
onBeforeMount(() => {
});
onMounted(() => {
});
</script>
<style lang="scss" scoped></style>
