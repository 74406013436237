import { defineStore } from "pinia"
import { computed, reactive, toRefs } from "vue"
import {
	RouterList,
	constantRouterMap
} from "@/router/index.js"
import {
	getToken,
	removeToken
} from "../../utils/auth"
import router from "@/router/index.js"
import { UserInfoStore } from "@/stores"
const modules = import.meta.glob("@/views/**/**.vue");
// console.log(modules)
export const GenerateRoutesStore = defineStore('Routes', () => {
	const userStore = UserInfoStore()
	const state = reactive({
		addRouters: [], //权限过滤后的路由
		routers: []     //权限过滤后的路由 + 首页等路由
	})
	// 合并路由
	const GenerateRoutes = (perm) => {
		return new Promise((resolve, reject) => {
			let accessedRouters = [] //可访问路由
			if (perm.includes('*')) {
				accessedRouters = flatMapMenulist(userStore.menuList)
			} else {
				accessedRouters = flatMapMenulist(userStore.menuList)
			}
			state.addRouters = [...RouterList,...translateComponent(userStore.menuList)]
			state.routers = [...RouterList, ...accessedRouters]
			console.log(accessedRouters,"扁平化处理")
			console.log(state.addRouters,"未扁平化")
			resolve(accessedRouters)
		})
	}
	// 通过权限过滤路由 
	const filterRoutes = (perm) => {
		// 如果后台处理 前台可以注释
		// const mapRouters = userStore.menuList.filter((v, i) => {
		// 	return perm.includes(v.path) != true
		// })
		// return translateComponent(mapRouters)
	}
	// 扁平化处理路由
	const flatMapMenulist = (data,num=1) => {
		let arr = []
		data.forEach((v, i) => {
				// 只保留一级菜单与二级菜单
				if(num==1||(num>1&&v.type==1)){
					const obj = {
						menuId: v.menuId, //菜单id
						parentId: v.parentId, //父级菜单id
						path: v.url,
						name: v.url ? v.url.replace(/\//g, '') : '',
						hidden: v.hidden,  //是否隐藏
						redirect: v.redirect ? v.redirect : v.list && v.list.length > 0 ? v.list[0].url : '',
						//替代 ()=>import  vite不支持
						component: num == 1 ? 
										(v.type == 0 ? modules['/src/views/Layout/Layout.vue']:modules[`/src/views${v.url}.vue`])
											: v.type == 1 ?  modules[`/src/views${v.url}.vue`] : '',
						meta: {
							title: v.name,
							icon: v.icon, //v.icon
							tabHidden: v.tabHidden,    //是否隐藏标签页
							levelHidden: v.levelHidden,//是否隐藏当前菜单目录
							activeMenu: v.activeMenu,  //是否展开当前菜单
							isCache: v.noKeepAlive,    //是否缓存当前菜单 默认不缓存
							noClosable: v.noClosable,  //当前标签页面是否不可关闭
							isLink:v.isLink,
						},
						num:num,
						children: v.list && v.list.length > 0 ? flatMapMenulist(v.list,num+1) : []
					}
					arr.push(obj)
				}else{
					//tree结构扁平化处理 排除3级及3级以上的layout组件
					arr=flatMapMenulist(v.list,num+1)
				}
				
		})
		return arr
	}
	// 导入component作为左侧菜单跳转使用
	const translateComponent = (data,num=1) => {
		const arr = []
		data.forEach((v, i) => {
				// tree结构扁平化处理
				const obj = {
					menuId: v.menuId, //菜单id
					parentId: v.parentId, //父级菜单id
					path: v.url,
					name: v.url ? v.url.replace(/\//g, '') : '',
					hidden: v.hidden,  //是否隐藏
					redirect: v.redirect ? v.redirect : v.list && v.list.length > 0 ? v.list[0].url : '',
					//替代 ()=>import  vite不支持
					component: num == 1 ? 
									(v.type == 0 ? modules['/src/views/Layout/Layout.vue']:modules[`/src/views${v.url}.vue`])
											:v.type == 0 ? modules['/src/views/RouterView/index.vue'] : v.type == 1 ? 
												modules[`/src/views${v.url}.vue`] : '',
					meta: {
						title: v.name,
						icon: v.icon, //v.icon
						tabHidden: v.tabHidden,    //是否隐藏标签页
						levelHidden: v.levelHidden,//是否隐藏当前菜单目录
						activeMenu: v.activeMenu,  //是否展开当前菜单
						isCache: v.noKeepAlive,    //是否缓存当前菜单 默认不缓存
						noClosable: v.noClosable,  //当前标签页面是否不可关闭
						isLink:v.isLink,
					},
					num:num,
					children: v.list && v.list.length > 0 ? translateComponent(v.list,num+1) : []
				}
				// console.log(obj.component)
				arr.push(obj)
				
		})
		return arr
	}
	const permission_routers = computed(() => {
		return state.addRouters
	})
	return {
		GenerateRoutes,
		permission_routers,
		...toRefs(state),
	}
})