import { defineStore } from "pinia"
import { reactive,computed,toRefs } from "vue"
import { getToken, removeToken, setToken,GetStorage } from "../../utils/auth"
import { getUserInfo } from "@/api/Login.js"
import { GetUserMenuList } from "@/api/menu.js"
export const UserInfoStore = defineStore('userStore', () => {
	const state = reactive({
		menuList: [],
		permissions:[],
		token: getToken('token'),
		isCollapse: false,
		user: '',
		Lockvisible:JSON.parse(GetStorage('Lockvisible'))||false,
		clientwidth:(window.innerWidth||document.documentElement.clientWidth) <= 430 ? true : false //适配手机端状态
	})
	// 获取用户信息及权限
	const GetUserInfo = () => {
		return new Promise(async (resolve, reject) => {
			const res = await getUserInfo()
			state.user = res.data
			const result= await GetUserMenuList()
			// console.log(result)
			state.menuList=result.data.menuList
			state.permissions=result.data.permissions
			resolve(state.permissions)
		})
	}
	const SETTOKEN = (token) => {
		state.token = token
		setToken('token', token)
	}
	// 前端登出
	const FedLogOut = () => {
		return new Promise((resolve, reject) => {
			removeToken('token')
			window.location.reload()
		})
	}
	const LockComputed=computed(()=>{
		return state.Lockvisible
	})
	const perm = computed(()=>{
		return state.permissions
	})
	return {
		...toRefs(state),
		GetUserInfo,
		FedLogOut,
		SETTOKEN,
		perm,
		LockComputed,
	}
})