// 伸缩开关
import Hamburger from "./Hamburger/index.vue";
// 面包屑
import Breadcrumb from "./Breadcrumb/index.vue";
// 个人中心
import Dropdown from "./Dropdown/Dropdown.vue";
// 表格
import Table from "./Table/index.vue";
// 主体内容区
import Main from "./Main/index.vue"
// 添加/重置/搜索等容器
import QueryForm from "./QueryForm/index.vue"
// 头部工具栏列表
import Tool from "./Tool/index.vue"
// Dialog表单
import Dialog from "./Dialog/index.vue"
// 抽屉
import Drawer from "./Drawer/index.vue"
// 锁屏model
import Lockoverlay from './Lockoverlay/index.vue'
// 富文本编译器
import Editor from './Editor/index.vue'
// Dplayer播放器
import Dplayer from './Dplayer/index.vue'
// 底部Footer
import Footer from './Footer/index.vue'
// 常规标签页
import CardTags from './Tags/cardTag.vue'
// 滑动标签页
import MoveTags from './Tags/index.vue'
// 卡片标签页
import Card from './Tags/Card.vue'
const comonpents = {
  Hamburger,
  Breadcrumb,
  Dropdown,
  Table,
  Main,
  QueryForm,
  Tool,
  Dialog,
  Drawer,
  Lockoverlay,
  Editor,
  Dplayer,
  Footer,
  MoveTags,
  CardTags,
  Card
};
const init = app => {
  for (let v in comonpents) {
    app.component(v, comonpents[v]);
  }
};
export default init;
