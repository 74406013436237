<template>
		<el-dropdown class="container" trigger="click" ref='el_drop' :style="{left:left,top:top}">
		<span class="el-dropdown-link">

		</span>
		<template #dropdown>
			<el-dropdown-menu>
				<!-- <el-dropdown-item icon="House">首页</el-dropdown-item> -->
				<el-dropdown-item @click='refresh'>刷新</el-dropdown-item>
				<el-dropdown-item @click="close">关闭</el-dropdown-item>
				<el-dropdown-item @click='closeAll'>关闭所有</el-dropdown-item>
				<el-dropdown-item @click="closeOther">关闭其他</el-dropdown-item>
				<el-dropdown-item @click="closeLeft">关闭左侧</el-dropdown-item>
				<el-dropdown-item @click="closeRight">关闭右侧</el-dropdown-item>
			</el-dropdown-menu>
		</template>
	</el-dropdown>
</template>

<script setup>
	import {
		ref
	} from "vue"
	defineOptions({
		name:'Drop'
	})
	const el_drop = ref(null)
	const props = defineProps({
		left: {
			type: String,
		},
		top: {
			type: String,
		}
	})
	const emit = defineEmits(['refresh', 'close', 'closeAll', 'closeOther', 'closeLeft', 'closeRight'])
	const refresh = () => {
		emit('refresh')
	}
	const close = () => {
		emit('close')
	}
	const closeAll = () => {
		emit('closeAll')
	}
	const closeOther = () => {
		emit('closeOther')
	}
	const closeLeft = () => {
		emit('closeLeft')
	}
	const closeRight = () => {
		emit('closeRight')
	}
	const handleDropOpen = () => {
		el_drop.value.handleOpen()
	}
	defineExpose({
		handleDropOpen,
	})
</script>

<style scoped lang='scss'>
	.container {
		position: absolute;
		z-index: 999;
	}
</style>