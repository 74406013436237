import { UserInfoStore } from "@/stores";
const permission = (el, { value }) => {
  if (value && value instanceof Array && value.length > 0) {
    const userStore = UserInfoStore();
    let hasPermission = false;
    let permlist = userStore.perm;
    let perm = value;
    hasPermission = permlist.some(v => {
      return perm.includes(v);
    });
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  } else {
    throw new Error(`指令错误,需要v-permission="['xxx']"`);
  }
};
export default permission;
