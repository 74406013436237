<template>
    <el-icon @click="toggleClick" class="hamburger" :class="{'is-active':isActive}">
     <Fold /></el-icon>
    <!-- <el-icon @click="toggleClick" class="hamburger" :class="{'is-active':isActive}" ><Switch /></el-icon> -->
</template>

<script setup>
	const emit=defineEmits(['handleClick'])
  defineOptions({
    name:'Hamburger'
  })
	import{reactive,toRefs} from "vue"
	const state=reactive({
		isActive:false
	})
	const toggleClick=()=>{
		 state.isActive=!state.isActive
		 emit('handleClick')
	}
	const {isActive} = toRefs(state)

</script>
<style scoped lang='scss'>
.hamburger {
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  // transform: rotate(90deg);
  transition: .38s;
  transform-origin: 50% 50%;
}

.hamburger.is-active {
  transform: rotate(-180deg);
}
</style>
