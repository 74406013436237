<!-- 抽屉 -->
<template>
    <el-drawer
    :title="title"
    :model-value="visible"
    :direction="direction"
    :append-to-body="true"
    :size="size"
    :before-close="handleClose"
  >
    <template #header>
        <slot name="header"></slot>
    </template>
    <slot></slot>
    <template #footer>
        <slot name="footer"></slot>
    </template>
  </el-drawer>
</template>

<script setup>
import { reactive, toRefs, onBeforeMount, onMounted } from "vue";
defineOptions({
  name: "Drawer",
});
defineProps({
    title:{
        type:String,
        default:''
    },
    visible:{
        type:Boolean,
        default:false,
    },
    direction:{
        type:String,
        default:'rtl', //ltr rtl ttb btt
    },
    size:{
        type:[String,Number],
        default:'20%'
    }
})
const emit = defineEmits(['handleClose'])
const state = reactive({});
const handleClose=()=>{
    emit('handleClose',false)
}
onMounted(() => {
});
</script>
<style lang="scss" scoped>
</style>
