<!-- video -->
<template>
  <div ref="videoPlayer" :style="{width:width+'px'}"></div>
</template>
<script setup>
defineOptions({
  name: "Dplayer",
});
const props=defineProps({
    width:{
        type:[String,Number],
        default:800,
    },
    url:{
        type:String,
        default:null
    }
})
import { reactive, toRefs, onBeforeMount, onMounted, ref, onUnmounted,onBeforeUnmount } from "vue";
import Hls from 'hls.js'
import DPlayer from 'dplayer'
const videoPlayer=ref(null)
const state = reactive({});
let dp = null
let hls = null
const initDPlayer=()=>{
    dp = new DPlayer({
        container: videoPlayer.value,
        autoplay: true,
        video: {
          url: props.url,
          live: true,
          type: 'customHls',
          customType: {
            customHls:  (video, player) =>{
            //   console.log(video)
              hls = new Hls()
              hls.loadSource(video.src)
              hls.attachMedia(video)
            },
          },
        },
      })
}
const hanleDplayerClose=()=>{
    if (dp != null) {
      dp.pause()
      dp.destroy()
      hls.destroy()
    }
}
defineExpose({
  hanleDplayerClose
})
onBeforeMount(() => {
});
onMounted(() => {
    initDPlayer()
});
onBeforeUnmount(()=>{
    hanleDplayerClose()
})
</script>
<style lang="scss" scoped>

</style>
