// 权限指令
import permission  from  "./perm/index.js"
const obj={
    permission,
}
const dir=(app)=>{
  for(let key in obj){
    app.directive(key,obj[key])
  }
}
export default dir