import {
  ElMessage,
  ElMessageBox,
  ElNotification,
  ElLoading,
} from "element-plus";
import config from "@/config/index.js";
const { duration } = config;
/**
 * @description 全局Message消息提示
 * @param {String} message  正文内容
 * @param {String} type    'success'|'warning'|'info'|'error'
 * @param {Boolean} plain   true 带背景 false不带
 * @param {Boolean} showClose  true可关闭 false不可
 * @param {Boolean} dangerouslyUseHTMLString  是否将message属性作为HTML片段处理
 *
 */
const $baseMessage = (
  message = "",
  type = "success",
  dangerouslyUseHTMLString,
  plain = true,
  showClose = true
) => {
  ElMessage({
    message,
    type,
    plain,
    showClose,
    dangerouslyUseHTMLString,
    duration,
  });
};
/**
 * @description 全局Alert
 * @param {String} content  正文内容
 * @param {String} Title    'title'
 * @param {Function} callback  回调函数
 * @param {Boolean} confirmButtonText  确定按钮文字
 * @param {Boolean} dangerouslyUseHTMLString  是否将content属性作为HTML片段处理
 *
 */
const $baseAlert = (
  content = "",
  callback,
  Title = "温馨提示",
  dangerouslyUseHTMLString,
  confirmButtonText = "确定"
) => {
  ElMessageBox.alert(content, Title, {
    confirmButtonText,
    dangerouslyUseHTMLString,
    callback: (action) => {
      if (callback) callback(action); //action: config cancel
    },
  });
};
/**
 * @description 全局Config
 * @param {String} content  正文内容
 * @param {String} Title    'title'
 * @param {Function} callback1  确定回调函数
 * @param {Function} callback2  取消回调函数
 * @param {String} type  提示类型   'success'|'warning'|'info'|'error'
 * @param {Boolean} confirmButtonText 确定按钮文字
 * @param {Boolean} cancelButtonText  取消按钮文字
 * @param {Boolean} dangerouslyUseHTMLString  是否将content属性作为HTML片段处理
 *
 */
const $baseConfirm = (
  content = "",
  Title = "提示",
  type,
  callback1,
  callback2,
  dangerouslyUseHTMLString,
  confirmButtonText = "确定",
  cancelButtonText = "取消"
) => {
  ElMessageBox.confirm(content, Title, {
    type,
    confirmButtonText,
    cancelButtonText,
    dangerouslyUseHTMLString,
  })
    .then(() => {
      if (callback1) callback1("config");
    })
    .catch(() => {
      if (callback2) callback2("cancel");
    });
};
/**
 * @description 全局Notify
 * @param {String} content  正文内容
 * @param {String} Title    'title'
 * @param {String} type  提示类型   'success'|'warning'|'info'|'error'
 * @param {String} position  弹出位置  'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
 * @param {Boolean} dangerouslyUseHTMLString  是否将content属性作为HTML片段处理
 *
 */
const $baseNotify = (
  title,
  message,
  position = "top-right",
  type = "success",
  dangerouslyUseHTMLString
) => {
  ElNotification({
    title,
    message,
    type,
    position,
    dangerouslyUseHTMLString,
    duration,
  });
};
/**
 * @description 全局加载层
 * @param {Boolean} lock 锁定屏幕滚动
 * @param {String} background 背景透明
 * @param {number} spinner 自定义加载图标类名
 * @param {string} text 显示在加载图标下方的加载文案
 *
 *  loadingInstance.close() 关闭
 */
const $baseLoading = (text, spinner, background = "hsla(0,0%,100%,.8)") => {
  return ElLoading.service({
    lock: true,
    text,
    spinner,
    background,
  });
};
const obj = {
  $baseMessage,
  $baseAlert,
  $baseConfirm,
  $baseNotify,
  $baseLoading,
};

export const install = (app) => {
  for (let key in obj) {
    // 注入
    app.provide(key, obj[key]);
    // const  {proxy} = getCurrentInstance() 调用
    app.config.globalProperties[key] = obj[key];
  }
};
