<template>
  <el-table
    :data="tableData"
    :table-layout="tableLayout"
    :border="border"
    :size="size"
    :stripe="stripe"
    :highlight-current-row="highlight"
    :default-sort="defaultSort"
    @selection-change="handleSelectionChange"
  >
    <el-table-column
      :width="60"
      v-if="selection == 'selection'&&tableData.length>0"
      align="center"
      :type="selection"
    />
    <el-table-column type="index" width="80" label="序号" align="center" v-if="tableData.length>0" />
    <el-table-column
      v-for="(item, i) in columnData"
      :sortable="item.sortable || false"
      :sort-method="item.sortMethod || null"
      :show-overflow-tooltip="item.tooltip || true"
      :prop="item.prop || ''"
      :label="item.label || ''"
      :formatter="item.formatter || null"
      :align="item.align || 'center'"
      :width="item.width || ''"
      :min-width="item.minWidth || ''"
      :fixed="item.fixed || false"
      :key="item.prop"
    />
    <!-- 如果需要使用插槽传值请使用 具名插槽operate或者匿名插槽 只支持这两种方式-->
    <!-- 其他需要显示的匿名插槽 -->
    <slot v-if="state.isDefaultSlots"></slot>
    <!-- 操作按钮插槽 有插槽优先使用插槽-->
    <slot name="operate" v-if="state.isOperateSlots"></slot>
    <!-- 默认携带操作按钮 -->
    <el-table-column
      v-if="!state.isOperateSlots&&operateList.length>0"
      label="操作"
      :width="
        operateWidth ||
        (operateList.length == 3 ? 300 : operateList.length == 2 ? 220 : 120)
      "
      align="center"
      fixed="right"
    >
      <template #default="scope">
        <template v-for="(v, index) in operateList" :key="index">
          <el-tooltip
            effect="dark"
            content="编辑"
            placement="top-start"
            v-if="v == 'edit'"
          >
            <el-button icon="Edit" @click="handleEdit(scope.row)"
              >编辑</el-button
            >>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            content="删除"
            placement="top-start"
            v-if="v == 'del'"
          >
            <el-button
              type="danger"
              icon="Delete"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </el-tooltip>
          <el-tooltip
            effect="dark"
            content="详情"
            placement="top-start"
            v-if="v == 'info'"
          >
            <el-button
              type="primary"
              icon="Document"
              @click="handleDetail(scope.row)"
              >详情</el-button
            >>
          </el-tooltip>
        </template>
      </template>
    </el-table-column>
    <template #empty>
      <el-empty  />
    </template>
  </el-table>
  <div class="page_box">
    <el-pagination
      background
      :current-page="page"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script setup>
import { reactive, toRefs, onBeforeMount, onMounted, useSlots } from "vue";
defineOptions({
  name: "Table",
});
defineProps({
  //table数据
  tableData: {
    type: Array,
    default: [],
  },
  //列标题
  columnData: {
    type: Array,
    default: [],
    // default: [
    //     {
    //         prop:'date',
    //         label:'时间',
    //         // 当内容过长被隐藏时显示
    //         tooltip:true,
    //         // 格式化列值
    //         formatter:(row,col,value)=>{
    //             return value
    //         },
    //         sortable:true,
    //         //自定义排序 与sort一样，仅当sortable设置为true的时候有效
    //         sortMethod:(a,b)=>{
    //            return a.xxx-b.xxx  从小到大  b.xxx-a.xxx 从大到小 return 0 默认 只接受数字
    //         },
    //         align:'center',
    //         width:180,
    //         minWidth:180,  //设置width固定宽度后的剩余宽度,平均分配给设置min-width属性的column 
    //         // 表头对齐方式 不设置默认使用align属性对齐方式
    //         headerAlign:'center',
    //         fixed:'right'
    //     }
    // ],
  },
  //表格单元、行和列的布局方式 auto fixed
  tableLayout: {
    type: String,
    default: "fixed",
  },
  //是否带有纵向边框
  border: {
    type: Boolean,
    default: true,
  },
  //是否为斑马纹 table
  stripe: {
    type: Boolean,
    default: false,
  },
  //Table 的尺寸  大 large  中 default  小 small
  size: {
    type: String,
    default: "default",
  },
  //是否开启全选
  selection: {
    type: String,
    default: "default",
  },
  //是否要高亮点击当前行
  highlight: {
    type: Boolean,
    default: false,
  },
  //默认排序 字段 升序降序   column需设置 sortable 字段为true
  defaultSort: {
    type: Object,
    default: {
      prop: "",
      order: "descending", //  升序:ascending  降序:descending
    },
  },
  limit: {
    type: [Number, String],
    default: 10,
  },
  page: {
    type: [Number, String],
    default: 1,
  },
  total: {
    type: Number,
    default: 0,
  },
  // 不使用插槽的操作按钮 默认编辑/删除/详细
  operateList: {
    type: Array,
    default: ["edit", "del", "info"],
  },
  // 操作按钮宽度
  operateWidth: {
    type: [Number, String, Boolean],
    default: null,
  },
});
const slots = useSlots();
const state = reactive({
  isDefaultSlots: false, //使用默认匿名插槽
  isOperateSlots: false, //使用具名插槽operate
});
// 验证是否使用插槽函数
const slotsFunction = () => {
  if (slots.operate && slots.operate()) {
      state.isOperateSlots = true;
  }
  if(slots.default && slots.default()){
    state.isDefaultSlots = true;
  }
};
const emit = defineEmits([
  "handleSelectionChange",
  "handleSizeChange",
  "handleCurrentChange",
  "handleEdit",
  "handleDelete",
  "handleDetail",
]);
// 编辑
const handleEdit = id => {
  emit("handleEdit", id);
};
// 删除
const handleDelete = id => {
  emit("handleDelete", id);
};
// 详细
const handleDetail = id => {
  emit("handleDetail", id);
};
// 复选框事件
const handleSelectionChange = v => {
  emit("handleSelectionChange", v);
};
// limit
const handleSizeChange = v => {
  emit("handleSizeChange", v);
};
// page
const handleCurrentChange = v => {
  emit("handleCurrentChange", v);
};
onMounted(async () => {
  await slotsFunction();
});
</script>
<style lang="scss" scoped>
.page_box {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
}
</style>
