import { createI18n } from "vue-i18n";
import { GetStorage, SetStorage } from "@/utils/auth.js";
// 导入国际化中文配置文件
import _zh_cn from "@/config/zh_cn.js";
// 导入国际化英文配置文件
import _en from "@/config/en.js";
const i18n = new createI18n({
  locale: GetStorage("locale") || "zh", // 语言标识 使用哪个语言
  legacy: false,
  messages: {
    //中文
    zh: {
      message: {
        ..._zh_cn,
      },
    },
    //英文
    en: {
      message: {
        ..._en,
      },
    },
  },
});
// 组件使用 {{ $t("message.hellow") }}
export default i18n;
