export default[ 
	{
		path: '/index',
		name: 'index',
		component: () => import('@/views/Layout/Layout.vue'),
		meta:{
			title:'message.home',
			icon:'HomeFilled',
		},
		children:[
			{
				path: '/home',
				name:'home',
				component: () => import('@/views/Home.vue'),
				meta:{
					title:'message.home',
					icon:'HomeFilled'
				},
			}
		]
	},
	{
		path: '/PassWord',
		name: 'PassWord',
		redirect:'/EditPassWord',
		hidden:true,
		component: () => import('@/views/Layout/Layout.vue'),
		meta:{
			title:'message.setting',
		},
		children:[
			{
				path: '/EditPassWord',
				name:'EditPassWord',
				component: () => import('@/views/PassWord/index.vue'),
				meta:{
					title:'message.editPassword',
					icon:'Edit'
				},
			},
			
		]
	},
]