import {
	defineStore
} from "pinia"
import {
	computed,
	reactive,
	toRefs,
	nextTick
} from "vue"
import router from '@/router/index.js'
import NProgress from "nprogress"; // 导入 nprogress模块
import {themeStore } from '@/stores/index.js'
export const TagviewsStore = defineStore('Tagviews', () => {
	const state = reactive({
		TagViews: [], 		// 存储tagsView 导航栏 
		cacheNameViews: [],  // 需要缓存的页面 
		routerKeyValue:router.currentRoute.value.path,
	})
	// 添加导航
	const addTagView = (route) => {
		if(!route.meta.tabHidden){
			if (state.TagViews.some((v, i) => route.path == v.path)) {
				return
			} else {
				state.TagViews.push({
					...route
				})
			}
		}
	}
	// 添加缓存页面
	const addCacheView = (route) => {
		if (route.meta.isCache) {
			if (state.cacheNameViews.includes(route.name)) return
			state.cacheNameViews.push(route.name)
		}
	}
	// 关闭当前 TagViews
	const delTagViews = (val) => {
		return new Promise((resolve, reject) => {
			for (let i in state.TagViews) {
				if (state.TagViews[i].path == val.path) {
					state.TagViews.splice(i, 1)
					break;
				}
			}
			if(router.currentRoute.value.path==val.path){
				let lastView = state.TagViews.slice(-1)[0]
				if (lastView) {
					router.push(lastView)
				} else {
					router.push('/')
				}
			}
			resolve()
		})
	}
	// 关闭当前 cacheNameViews
	const delcacheNameViews = (val) => {
		return new Promise((resolve, reject) => {
			if (state.cacheNameViews.includes(val.name)) {
				for (let i in state.cacheNameViews) {
					if (state.cacheNameViews[i] == val.name) {
						state.cacheNameViews.splice(i, 1)
						break;
					}
				}
			}
			resolve()
		})
	}
	// 关闭全部
	const delTagViewsAll = () => {
		state.cacheNameViews = []
		state.TagViews = []
		router.push('/')
	}
	// 关闭其他 TagViews
	const delTagViewsOther = (val) => {
		return new Promise((resolve, reject) => {
			for (let i in state.TagViews) {
				if (state.TagViews[i].path == val.path) {
					state.TagViews = state.TagViews.slice(i, Number(i) + 1)
					break;
				}
			}
			resolve()
		})
	}
	// 关闭其他 cacheNameViews
	const delCacheNameViewsOther = (val) => {
		return new Promise((resolve, reject) => {
			for (let i in state.cacheNameViews) {
				if (state.cacheNameViews[i] == val.name) {
					state.cacheNameViews = state.cacheNameViews.slice(i, Number(i) + 1)
					break;
				}
			}
			resolve()
		})
	}
	// 关闭左侧 TagViews
	const delTagViewsLeft = (val) => {
		return new Promise((resolve,reject)=>{
			let delList=[]
			for (let i in state.TagViews) {
				if (state.TagViews[i].path == val.path) {
					 delList=state.TagViews.splice(0,i)
					break;
				}
			}
			delCacheNameViewsLeft([...delList])
			resolve([...delList])
		})
	}
	// 关闭左侧 cacheNameViews
	const delCacheNameViewsLeft = (res=[])=>{
		return new Promise((resolve,reject)=>{
			res.forEach((v,i)=>{
				state.cacheNameViews.forEach((val,ide)=>{
					if(v.name==val){
						state.cacheNameViews.splice(ide,1)
					}
				})
			})
			resolve()
		})
	}
	// 关闭右侧 TagViews
	const delTagViewsRight = (val) => {
		return new Promise((resolve,reject)=>{
			let delList=[]
			for (let i in state.TagViews) {
				if (state.TagViews[i].path == val.path) {
					console.log(val,i)
					 delList=state.TagViews.splice(Number(i)+1)
					break;
				}
			}
			console.log(delList)
			delCacheNameViewsRight([...delList])
			resolve([...delList])
		})
	}
	// 关闭右侧 cacheNameViews
	const delCacheNameViewsRight = (res=[]) => {
		return new Promise((resolve,reject)=>{
			console.log(res)
			res.forEach((v,i)=>{
				state.cacheNameViews.forEach((val,ide)=>{
					if(v.name==val){
						state.cacheNameViews.splice(ide,1)
					}
				})
			})
			resolve()
		})
	}
	// 刷新当前页面
	const refreshCurrentView=(val)=>{
		console.log(val)
		return new Promise((resolve,reject)=>{
			const theme_store = themeStore();
				// true说明是刷新其他页面 false右键刷新当前页面或者顶部刷新当前页面
				if(val&&router.currentRoute.value.path!=val.path){
					if(val.meta.isCache){
						// 清除缓存组件
						state.cacheNameViews.splice(state.cacheNameViews.indexOf(val.name),1)
					}
					// 先清除缓存 在跳转 否则页面闪烁 nexttick无效
					setTimeout(()=>{
						router.replace(val)
					},50)
				}else{
					// 头部刷新按钮或者右键刷新当前页面
					if(theme_store.theme.showProgressBar)NProgress.start();
					state.routerKeyValue=null
					if(router.currentRoute.value.meta.isCache){
						// 清除缓存组件 
						state.cacheNameViews.splice(state.cacheNameViews.indexOf(router.currentRoute.value.name),1)
					}
						nextTick(()=>{
							state.routerKeyValue=router.currentRoute.value.path
							if(router.currentRoute.value.meta.isCache){
								state.cacheNameViews.splice(state.cacheNameViews.indexOf(router.currentRoute.value.name),0,router.currentRoute.value.name)
							}
						})
					// console.log(state.cacheNameViews)
					NProgress.done();
				}
			resolve()
		})
	}
	const addTags = (route) => {
		addTagView(route)
		addCacheView(route)
	}
	const KeyValue=computed(()=>state.routerKeyValue)
	const TagViewsList = computed(() => state.TagViews)
	const cacheNameViewsList = computed(() => state.cacheNameViews)
	const {
		TagViews,
		cacheNameViews,
		routerKeyValue,
	} = toRefs(state)
	return {
		KeyValue,
		TagViews,
		cacheNameViews,
		addTagView,
		addCacheView,
		addTags,
		routerKeyValue,
		TagViewsList,
		cacheNameViewsList,
		delCacheNameViewsOther,
		delTagViewsOther,
		delTagViewsAll,
		delcacheNameViews,
		delTagViews,
		delTagViewsLeft,
		delCacheNameViewsLeft,
		delTagViewsRight,
		delCacheNameViewsRight,
		refreshCurrentView
	}
})