import request from "@/utils/request.js"
// 登录
export const Login = (params)=>{
    return request({
        url:'/json/login.json',
        method:'get',
        params
    })
}
// 验证码 
export const getCaptcha =(params)=>{
    return request({
        url:'/captcha.jpg',
        method:'get',
        params
    })
}
//查询用户信息 
export const getUserInfo =(params)=>{
    return request({
        url:'/json/user.json',
        method:'get',
        params
    })
}
