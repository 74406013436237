// 国际化英文配置
const en={
    hellow: "hellow word",
    home:'Home',
    set:'set',
    editPassword:'EditPassword',
    setting:'Setting',
    user:'User',
    role:'Role',
    menu:'Menu',
    params:'Params',
    sys:'SystemLog',
    center:'UserCenter',
    tool:'Tool',  
    colorTip:'ColorTip',
    voice:'VoiceRecognition',
    outLink:'outLink',
    board:'LookBoard',
    iframe:'iframe',
    search:'KuaKesearch',
    bili:'Bili',
    export:'Export',
    exportExcel:'ExportExcel',
    import:'Import',
    importExcel:'ImportExcel',
    component:'Component',
    print:'Print',
    watermark:'WaterMark',
    drag:'Drag',
    fullScreen:'FullScreen',
    Editor:'Editor',
    share:'Share',
    tag:'slideTags',
    anchor:'anchorPosition',
    route:'Menu-1',
    fail:'404',
    sideColumn:'NoSideColumn',
}
export default en