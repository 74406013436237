// 路由配置文件
import router from "./route.config.js"
// axios配置文件
import request from "./net.config.js"
// 主题配置文件
import theme from "./theme.config.js"
// 高德地图配置文件
import map from "./map.config.js"
// 通用配置 
import setting from "./setting.config.js"
export default {
	...router, 
	...request,
	...theme,
	...map,
	...setting,
}
