// 国际化简体中文配置
const zh_cn={
    hellow: "你好",
    home:'首页',
    set:'设置',
    editPassword:"修改密码",
    setting:'系统管理',
    user:'用户管理',
    role:'角色管理',
    menu:'菜单管理',
    params:'参数管理',
    sys:'日志管理',
    center:'个人中心',
    tool:'工具管理',
    colorTip:'取色器',
    voice:'声纹识别',
    outLink:'外链',
    board:'看板',
    iframe:'iframe',
    search:'夸克搜索',
    bili:'哔哩哔哩',
    export:'导出',
    exportExcel:'导出Excel',
    import:'导入',
    importExcel:'导入Excel',
    component:'组件',
    print:'打印',
    watermark:'水印',
    drag:'拖拽',
    fullScreen:'全屏',
    Editor:'富文本',
    share:'分享',
    tag:'滑动标签页',
    anchor:'锚点定位',
    route:'多级路由缓存',
    fail:'404',
    sideColumn:'无侧栏',
}
export default zh_cn