<!-- Footer -->
<template>
  <div class="container">
    <el-divider border-style="dashed" style="margin: 0" />
    <span class="ba">
      <a href="https://beian.miit.gov.cn" target="_blank">{{ ICP }}</a>
      <img :src="Path('beian.png')" alt="" />
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=23120202000219" rel="noreferrer" target="_blank">{{ GALW }}</a>
    </span>
  </div>
</template>

<script setup>
import config from "@/config/index.js";
const { ICP, GALW } = config;
const Path = name => new URL(`../../assets/${name}`, import.meta.url).href;
defineOptions({
  name: "Footer",
});
import { reactive, toRefs, onBeforeMount, onMounted } from "vue";
const state = reactive({});
</script>
<style lang="scss" scoped>
.container {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  a,.ba span {
    font-size: 12px;
    color: #333;
  }
  a:hover,
  .ba span:hover {
    color: var(--el-menu-hover-bg-color);
  }
  .ba {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 16px;
      height: 16px;
      margin: 0 4px 0 20px;
    }
  }
}
</style>
