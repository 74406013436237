<!-- 富文本编译器 -->
<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="state.toolbarConfig"
      :mode="state.mode"
    />
    <Editor
      :style="{ height: height + 'px', 'overflow-y': 'hidden' }"
      v-model="state.value"
      :defaultConfig="state.editorConfig"
      :mode="state.mode"
      @onCreated="handleCreated"
      @onChange="handleChange"
    />
  </div>
</template>

<script setup>
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { postAddFile } from "@/api/file.js"
import config from "@/config/index.js"
const { baseURL } = config
defineProps({
  height: {
    type: [Number, String],
    default: 350,
  },
});
defineOptions({
  name: "Editor",
});
const emit = defineEmits(['handleEditorChange'])
import { reactive, toRefs, onBeforeMount, onMounted,shallowRef,onBeforeUnmount } from "vue";
// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();
const state = reactive({
  value: "",
  mode: "default", //或者 简洁模式simple
  toolbarConfig: {},
  editorConfig: {
    placeholder: "请输入内容...",
    MENU_CONF: {
      uploadImage: {
        // 小于该值就插入 base64 格式（而不上传），默认为 0
        base64LimitSize: 5 * 1024, // 5kb
        // 单个文件的最大体积限制，默认为 2M
        // maxFileSize: 1 * 1024 * 1024, // 1M
        // // 最多可上传几个文件，默认为 100
        // maxNumberOfFiles: 5,
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ["image/*"],
        // 自定义上传
        async customUpload(file, insertFn) {
          // 文件上传
          const formData = new FormData();
          formData.append("file", file);
          // 这里根据自己项目封装情况，设置上传地址
          const res = await postAddFile({ type: "image" }, formData);
          const wor = JSON.parse(res.msg);
          const url = baseURL + "/" + wor.realPath;
          // 插入到富文本编辑器中，主意这里的三个参数都是必填的，要不然控制台报错：typeError: Cannot read properties of undefined (reading 'replace')
          insertFn(url, wor.fileName, wor.fileName);
        },
      },
    },
  },
});
const handleChange = v => {
  let value=state.value=='<p><br></p>'?'':state.value
  emit('handleEditorChange',value)
};
const handleCreated = (editor) => {
  // 记录 editor 实例，重要！
  editorRef.value = editor;
};
// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  if (editorRef.value == null) return;
  editorRef.value.destroy();
});
</script>
<style lang="scss" scoped></style>
