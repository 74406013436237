<!-- 表单添加修改 -->
<template>
    <el-dialog
    :destroy-on-close="true"
    :draggable="draggable"
    :model-value="dialogVisible"
    :title="title"
    :width="width"
    :before-close="handleClose"
  >
    <el-form ref="ruleFormRef" label-width="auto" :rules="rules" :model="state.form">
      <el-row :gutter="20">
        <el-col v-for="(item, i) in formData" :key="i" :span="item.span || 12">
          <el-form-item
            :prop="item.prop"
            :label="item.label"
            :label-width="item.width || ''"
            :size="item.size || ''"
            :rules="[
              {
                required: item.required || false,
                message:
                  (item.type == 'text' || item.type == 'textarea'
                    ? '请输入'
                    : '请选择') + item.label || '',
                trigger: item.trigger||'',
              },
              { validator: item.validator?item.validator:(rule,value,callback)=>{callback()},trigger: (item.type=='text'||item.type=='textarea')?'blur':'change',},
            ]"
          >
            <!-- 输入框 -->
            <el-input 
                v-model="state.form[item.prop]"
                style="width: 100%"
                v-if="item.type == 'text'"
                :placeholder="'请输入' + item.label"
                clearable
                >
                <template #append v-if="item.append">
                  <span @click="handleAppend" pointer>{{ item.appendText||'' }}</span>
                </template>
            </el-input>
            <!-- 文本域 -->
            <el-input
              v-model="state.form[item.prop]"
              v-if="item.type == 'textarea'"
              :type="item.type"
              :placeholder="'请输入' + item.label"
              clearable
            />
            <!-- 下拉选择 -->
            <el-select
              v-model="state.form[item.prop]"
              style="width: 100%"
              v-if="item.type == 'select'"
              clearable
              :multiple="item.multiple||false"
              :collapse-tags="item.multiple||false"
              :collapse-tags-tooltip="item.multiple||false"
              :max-collapse-tags="item.max||1"
              :placeholder="'请选择' + item.label"
              :filterable="item.filterable||false"
              @change='(val)=>{
                selectChange(val,item.prop)
              }'
            >
              <el-option
                v-for="(val, index) in item.options"
                :key="val.value"
                :label="val.label"
                :value="val.value"
              />
            </el-select>
            <!-- 级联选择 -->
            <el-cascader
              v-model="state.form[item.prop]"
              ref="cascaderRef"
              v-if="item.type == 'cascader'"
              style="width: 100%"
              :props="item.props"
              :placeholder="'请选择' + item.label"
              clearable
              @change="handleCascaderChange"
            />
            <!-- 年/月/年月日/多个年月日/周/日期时间/日期时间范围/日期范围/月份范围 -->
            <el-date-picker
              style="width: 100%"
              v-if="
                item.type == 'year' ||
                item.type == 'month' ||
                item.type == 'date' ||
                item.type == 'dates' ||
                item.type == 'week' ||
                item.type == 'datetime' ||
                item.type == 'datetimerange' ||
                item.type == 'daterange' ||
                item.type == 'monthrange'
              "
              v-model="state.form[item.prop]"
              :type="item.type"
              :placeholder="'请选择' + item.label"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :format="item.type=='week'?' YYYY 年 第 ww 周':null"
              :value-format="item.type == 'year'?'YYYY'
              :item.type == 'month'?'YYYY-MM':item.type == 'date'?'YYYY-MM-DD'
              :item.type == 'dates'?'YYYY-MM-DD'
              :item.type == 'datetime'?'YYYY-MM-DD HH:mm:ss'
              :item.type == 'datetimerange'?'YYYY-MM-DD HH:mm:ss'
              :item.type == 'daterange'?'YYYY-MM-DD'
              :item.type == 'monthrange'?'YYYY-MM':''"
            />
            <!-- 开关 -->
            <el-switch 
              v-model="state.form[item.prop]" 
              v-if="item.type == 'switch'" 
              :active-value="item.activeValue"
              :inactive-value="item.inactiveValue"
            />
            <!-- 评分 -->
            <el-rate
                v-model="state.form[item.prop]"
                v-if="item.type == 'rate'" 
                :texts="item.texts"
                :show-text="item.showText"
                text-color="#ff9900"
              />
            <!-- 复选框 -->
            <el-checkbox-group 
              v-model="state.form[item.prop]" 
              v-if="item.type == 'checkbox'"
              >
              <el-checkbox
                v-for="(val, index) in item.options"
                :key="index"
                :value="val.value"
              >
                {{ val.label }}
              </el-checkbox>
            </el-checkbox-group>
            <!-- 单选按钮 -->
            <el-radio-group 
              v-model="state.form[item.prop]"
              v-if="item.type == 'radio'"
              >
              <el-radio
                v-for="(val, index) in item.options"
                :value="val.value"
                :name="val.value"
                :key="index"
              >
                {{ val.label }}
              </el-radio>
            </el-radio-group>
            <!-- 数字文本框 -->
            <el-input-number
              v-model="state.form[item.prop]"
              v-if="item.type == 'input_number'"
              :min="Number(item.min) || '-Infinity'"
              :max="Number(item.max) || 'Infinity'"
            />
            <!-- 图片上传 -->
            <el-upload
              v-model:file-list="state.form[item.prop]"
              v-if="item.type == 'upload_img'"
              :http-request="httpRequest"
              :data="item"
              :before-remove="()=>{
                return handleRemove(item)
              }"
              :on-preview="(file)=>{
                return handlePictureCardPreview(file,item)
              }"
              :class="{ disabled: item.uploadStatus }"
              :limit="item.limit||1"
              list-type="picture-card"
            >
              <el-icon>
                <Plus />
              </el-icon>
            </el-upload>
            <!-- 取色器 -->
            <el-color-picker v-if="item.type=='color'" v-model="state.form[item.prop]" />
            <!-- tree选择器  defaultExpandedKeys默认展开 defaultCheckedKeys默认选中-->
            <el-tree
              v-if="item.type == 'tree'"
              ref="treeRef"
              :data="item.options"
              show-checkbox
              :props="item.defaultProps||{children:'children',label:'label'}"
              :node-key="item.nodeKey||'id'"
              :default-expanded-keys="item.defaultExpandedKeys||[]"
              @check="handleTreeChange(item.prop,item.nodeKey||'id')"
            />
            <!-- 分割线 -->
            <el-divider v-if="item.type=='divider'" :content-position="item.position||'left'">{{ item.title||'' }}</el-divider>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="Cancel">取消</el-button>
        <el-button type="primary" @click="Config">确定</el-button>
      </div>
    </template>
    <!-- 图片预览组件 -->
    <ElImageViewer v-if="state.isShow" :url-list="state.previewList" :initial-index="state.initialIndex" @close="closePreview"></ElImageViewer>
  </el-dialog>
</template>

<script setup>
defineOptions({
  name: "Dialog",
});
import dayjs from "dayjs"
import { reactive, toRefs, onBeforeMount, onMounted, ref, nextTick } from "vue";
import { postAddFile } from "@/api/file.js"
import { ElImageViewer } from 'element-plus';
import 'element-plus/theme-chalk/el-image-viewer.css';
import config from "@/config/index.js"
const { baseURL } = config
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  width: {
    type: [Number, String],
    default: "60%",
  },
  draggable: {
    type: Boolean,
    default: true,
  },
  formData: {
    type: Array,
    default:[],
  },
  rules: {
    type: Object,
    default: {},
  },
});

const ruleFormRef = ref(null);
const cascaderRef = ref(null);
const treeRef=ref(null)
const emit = defineEmits(["update:dialogVisible","update:formData",'submit','handleAppend','selectChange']);
const state = reactive({
  form:{},
  previewList:[],
  isShow:false,
  initialIndex:0
});
// input尾部按钮事件
const handleAppend=()=>{
  emit('handleAppend')
}
const handleClose = () => {
  ruleFormRef.value.resetFields()
  // 恢复上传图片的状态
  let arr=props.formData
  arr.forEach((v,i)=>{
    if(v.hasOwnProperty('uploadStatus')){
      v.uploadStatus=false
    }
  })
  emit("update:formData", arr);
  emit("update:dialogVisible", false);
};
const Cancel = () => {
  handleClose()
};
const Config = () => {
  ruleFormRef.value.validate(async(valid, fields) => {
    if (valid) {
      await emit('submit',{...state.form})
      handleClose()
    } else {
      console.log(fields, "fields");
    }
  });
};
// select事件
const selectChange=(v,type)=>{
  emit('selectChange',v,type)
}
// tree事件
const handleTreeChange=(e,id)=>{
  // tree 返回所有选中的节点 
  let arr=treeRef.value[0].getCheckedNodes(false,true).map((v)=>{
    return v[id]
  })
  state.form[e]=[...arr] 
  console.log(arr)
  ruleFormRef.value.validateField(e);
}
// tree 数据回显必须调用
const handleTreeUpdate=()=>{
  nextTick(()=>{
    props.formData.forEach((v)=>{
      if(v.type=='tree'){
          const tree=v.value
          tree.forEach((v)=>{
            treeRef.value[0].setChecked(v,true,false)
          })
      }
    })
  })
}
// 行政区划
const handleCascaderChange = () => {
  cascaderRef.value[0].togglePopperVisible(false);
};
// 上传图片
const httpRequest = async (file) => {
  // console.log(file)
  const formData = new FormData();
  formData.append("file", file.file);
  const res = await postAddFile({ type: "image" }, formData).catch((err)=>{
    // console.log(state.form)
  })
  if (res.code == 0) {
    state.form[file.data.prop].forEach(v => {
      if (v.uid == file.file.uid) {
        v.msg = res.msg;
      }
    });
    // 隐藏添加按钮
    if (state.form[file.data.prop].length == (file.data.limit||1)) {
        let arr=props.formData
        arr.forEach((v)=>{
            if(v.prop==file.data.prop){
              v.uploadStatus=true
            }
        })
        emit("update:formData",arr);
    }
    ruleFormRef.value.validateField(file.data.prop);
  }
  // console.log(state.form)
};
// 删除图片
const handleRemove = (val) => {
      // console.log(val)
      // 显示添加按钮
      let arr=props.formData
      arr.forEach((v)=>{
        if(v.prop==val.prop){
          v.uploadStatus=false
        }
      })
    emit("update:formData",arr);
    // console.log(props.formData)
    // console.log(state.form)
};
// 预览图片
const handlePictureCardPreview = (file,val) => {
  let arr=[]
  for(let key in state.form){
    if(key==val.prop){
      // console.log(state.form[key])
      state.form[key].forEach((v,i)=>{
        arr.push(v.url)
        if(file.url==v.url){
          state.initialIndex=i
        }
      })
    }
  }
  state.previewList=arr
  state.isShow=true
};
// 关闭预览
const closePreview=()=>{
  state.isShow=false
}
defineExpose({
  form:state.form,
  handleTreeUpdate
})
onBeforeMount(()=>{
})
onMounted(() => {
    const data=JSON.parse(JSON.stringify(props.formData))
    console.log(data,"dialog")
    if(Array.isArray(data)&&data.length>0){
      data.forEach((v)=>{
        if(v.prop){
          state.form[v.prop]=v.value
        }
      })
    }
    console.log(state.form,"state.form")
});
</script>
<style lang="scss" scoped>
::v-deep(.disabled .el-upload--picture-card) {
  display: none;
}
::v-deep(.el-tree) {
  flex: 1;
}

</style>
