// 通用配置
const config = {
  title:'Admin',
  // Message与Notify 消息提示显示时间
  duration: 3000,
  // ICP备案号
  ICP:'黑ICP备2024025241号',
  // 公安联网备案号
  GALW:'黑公网安备23120202000219'
};
export default config;
