/**
 * @description  scrollTo 封装 适用锚点定位或滚动条定位
 * @param {String} el 滚动条盒子
 * @param {String} targetPosition 目标位置
 * @param {Boolean} duration  动画持续时间
 * @param {Boolean} direction 方向
 *
 */
export const smoothScrollTo = (value = { el: window, targetPosition: 0, duration: 500, direction: "top" }) => {
  const { el, targetPosition, duration, direction } = value;
  // 开始位置
  const startPosition = direction == "top" ? el.scrollTop : el.scrollLeft;
  // 结束位置-开始位置 算出差值
  const distance = targetPosition - startPosition;
  // 定义变量存储开始时间
  let startTime = null;
  // 运动函数运动速度
  const easeInOutQuad = t => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };
  const animation = currentTime => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    // 设置最大比例为1 当过渡时间与动画持续时间比例等于1时 说明动画过渡时间结束 那么startPosition + distance * easedProgress也就等于当前的targetPosition位置
    const progress = Math.min(timeElapsed / duration, 1);
    // 运动函数 加速匀速减速
    const easedProgress = easeInOutQuad(progress);
    // 方向
    if (direction == "top") {
      el.scrollTo(0, startPosition + distance * easedProgress);
    } else {
      el.scrollTo(startPosition + distance * easedProgress, 0);
    }
    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  };
  requestAnimationFrame(animation);
};
/**
 * @description 随机生成16进制颜色
 *
 */
export const randomColor = () => {
  let list = [];
  for (let i = 0; i < 10; i++) {
    list.push(i);
  }
  for (let i = 65; i <= 70; i++) {
    list.push(String.fromCharCode(i));
  }
  let str = "";
  for (let i = 0; i < 6; i++) {
    str += list[parseInt(Math.random() * (list.length - 0))];
  }
  return "#" + str;
};
