<!-- 标签页 -->
<template>
  <div class="scroll" ref="scroll">
    <div
      ref="tag"
      :class="['item',TabsValue==item.path?'active':'']"
      v-for="(item, i) in TagViewsList"
      :key="item.path"
      @click="handleTags(item,i)"
      @contextmenu.prevent="openMenu($event, i, item)"
    >
      <span class="icon" v-if="item.meta.icon && theme_store.theme.showTabsIcon">
        <el-icon :size="14">
          <component :is="item.meta.icon"></component>
        </el-icon>
      </span>
      <span class="title"> {{ $t(item.meta.title) }} </span>
      <span class="close" @click.stop="removeTab(item,i)">
        <el-icon :size="14">
          <component is="Close"></component>
        </el-icon>
      </span>
    </div>
  </div>
  <Drop
    v-show="visible"
    @refresh="refresh"
    @close="close"
    @closeAll="closeAll"
    @closeOther="closeOther"
    @closeLeft="closeLeft"
    @closeRight="closeRight"
    ref="drop"
    :left="left"
    :top="top"
  />
</template>

<script setup>
defineOptions({
  name: "MoveTags",
});
import { VueDraggable, vDraggable } from "vue-draggable-plus";
import { reactive, toRefs, onBeforeMount, onMounted, ref,watch } from "vue";
import { smoothScrollTo } from "@/utils/index.js";
import { TagviewsStore, themeStore } from "@/stores/index.js";
import config from "@/config/index.js";
const { theme_config } = config;
import { storeToRefs } from "pinia";
import Drop from "@/views/Layout/TagsView/component/Drop.vue";
import { useRouter, useRoute } from "vue-router";
import { nextTick } from "vue";
const router = useRouter();
const route = useRoute();
const useViewStore = TagviewsStore();
const theme_store = themeStore();
const {
  TagViewsList,
  cacheNameViews,
  TagViews,
  delcacheNameViews,
  KeyValue,
  routerKeyValue,
} = storeToRefs(useViewStore);
const state = reactive({
    TabsValue: "/home",
    left: "0",
    top: "0",
    visible: false,
    value: null,
})
const { TabsValue, left, top, visible } = toRefs(state);
const tag = ref(null);
const scroll = ref(null);
const drop= ref(null)
const removeTab = (v,i) => {
  let delview = "";
  for (let i in TagViews.value) {
    if (TagViews.value[i].path == v.path) {
      delview = TagViews.value.splice(i, 1);
      break;
    }
  }
  if (router.currentRoute.value.path == v.path) {
    let lastView = TagViews.value.slice(-1)[0];
    if (lastView) {
      router.push(lastView);
    } else {
      router.push("/");
    }
  }
  useViewStore.delcacheNameViews(delview[0]);
};
const handleTags = (v, i) => {
    router.push({
        path: v.path,
        query: v.query,
    });
};
const addTag = () => {
    let route = router.currentRoute.value;
    state.TabsValue = route.path;
    useViewStore.addTags(route);
    console.log(cacheNameViews);
    console.log(TagViews);
    routerKeyValue.value = route.path;
    // 移动标签页到可视区域
    TagViews.value.forEach((v,i)=>{
        if(v.path==route.path){
            nextTick(()=>{
                // const left = tag.value[i].offsetLeft
                // console.log(tag.value[i].offsetLeft,'tag.value[i].offsetLeft')
                // console.log(scroll.value.offsetWidth,'scroll.value.offsetWidth')
                // console.log(tag.value[i].offsetWidth,'tag.value[i].offsetWidth')
                const left = tag.value[i].offsetLeft - (scroll.value.offsetWidth - tag.value[i].offsetWidth) / 2;
                smoothScrollTo({
                    el: scroll.value,
                    targetPosition: left,
                    duration: 400,
                    direction: "left",
                });
            })
        }
    })
};
const openMenu = (e, i, item) => {
  state.visible = true;
  state.value = item;
  state.left = e.clientX + "px";
  state.top = tag.value[i].getBoundingClientRect().top + 20 + "px";
  drop.value.handleDropOpen();
};
const refresh = () => {
  useViewStore.refreshCurrentView(state.value);
};
const close = v => {
  useViewStore.delTagViews(state.value);
  useViewStore.delcacheNameViews(state.value);
};
const closeAll = v => {
  useViewStore.delTagViewsAll();
};
const closeOther = v => {
  router.push(state.value);
  useViewStore.delTagViewsOther(state.value);
  useViewStore.delCacheNameViewsOther(state.value);
};
const closeLeft = v => {
  useViewStore.delTagViewsLeft(state.value).then(res => {
    console.log(res);
    const list = res;
    list.forEach((v, i) => {
      if (v.path == router.currentRoute.value.path) {
        router.push(state.value);
      }
    });
  });
};
const closeRight = v => {
  useViewStore.delTagViewsRight(state.value).then(res => {
    console.log(res);
    const list = res;
    list.forEach((v, i) => {
      if (v.path == router.currentRoute.value.path) {
        router.push(state.value);
      }
    });
  });
};
watch(
  () => router.currentRoute.value,
  (newval, oldval) => {
    addTag();
  }
);
onBeforeMount(() => {});
onMounted(() => {
  addTag();
});
</script>
<style lang="scss" scoped>
.scroll {
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: relative;
  margin: 10px 0 0 0;
  height: 40px;
}
.scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.item {
  display: flex;
  align-items: center;
  flex: none;
  cursor: pointer;
  margin: 0 14px;
  height: 40px;
  position: relative;
}
.item .icon {
  height: 18px;
  margin: 0 4px 0 0;
}
.item .title {
  font-size: 14px;
  font-weight: 500;
  color: var(--el-text-color-primary);
}
.item .close {
  transform: scale(0);
  transition: transform 0.3s linear;
}
.item .close .el-icon {
  border-radius: 50%;
  transition: all 0.3s ease-out;
}
.item .close:hover .el-icon {
  background: var(--el-text-color-placeholder);
  color: #fff;
}
.item:first-child {
  margin-left: 0;
}
.item:last-child {
  margin-right: 0;
}
.item:hover {
  color: var(--el-color-primary);
  .title {
    color: var(--el-color-primary);
  }
  .close {
    transform: scale(1);
  }
  &::after {
    width: 100%;
  }
}
.active{
    color: var(--el-color-primary);
    .title{
        color: var(--el-color-primary);
    }
    .close {
        transform: scale(1);
    }
    &::after {
        width: 100% !important;
    } 
}
.item::after {
  content: "";
  display: inline-block;
  height: 2px;
  width: 0;
  background: var(--el-color-primary);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.2s linear;
}
.close {
  margin: 0 0 0 4px;
  height: 18px;
}
</style>
