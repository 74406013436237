import { defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";
import { GetStorage, SetStorage } from "@/utils/auth.js";
import { useI18n } from "vue-i18n";

export const i18nStore = defineStore("i18n", () => {
  const I18n = useI18n();
  const { locale } = I18n;
  const state = reactive({
    locale: GetStorage("locale") || "zh",
    //国际化语言 是否显示切换选项
    localeList: [
      {
        value: "zh",
        label: "简体中文",
        show: true, //是否显示国际化切换按钮
      },
      {
        value: "en",
        label: "English",
        show: true,
      },
    ],
  });
  const GetComlocale = computed(() => state.locale);
  function EditLocale(v) {
    state.locale = v;
    locale.value=v
    SetStorage("locale", v);
  }
  return {
    GetComlocale,
    EditLocale,
    ...toRefs(state),
  };
});
