import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import config from "@/config"
// 导入路由下所有.js路由文件
const Routers = import.meta.globEager("./module/*.js");
const RouterList = [];
Object.keys(Routers).forEach((item) => {
	Object.keys(Routers[item]).forEach((key) => {
		RouterList.push(...Routers[item][key]);
	});
});
export const constantRouterMap = [
	{
		path: '/',
		hidden:true,
		redirect: '/home',
	},
	{
		path: '/Login',
		name: 'Login',
		hidden:true,
		component: () => import('@/views/Login.vue'),
		meta: {
			title: "登录",
		}
	},
	{
		path: '/register',
		name: 'register',
		hidden:true,
		component: () => import('@/views/register.vue'),
		meta: {
			title: "注册",
		}
	},
]

const router = createRouter({
	history: createWebHashHistory(import.meta.env.BASE_URL),
	routes:constantRouterMap
})
export default router
export  { RouterList } 
