import request from "@/utils/request.js"
// 查询用户权限菜单
export const GetUserMenuList=(params)=>{
    return request({
        url:'/json/menu.json',
        method:'get',
        params
    })
}
// 查询全部菜单
export const GetAllMenuList=(params)=>{
    return request({
        url:'/Sys/menu/list',
        method:'get',
        params
    })
}
// 查询目录
export const GetMenuSelect=(params)=>{
    return request({
        url:'/Sys/menu/select',
        method:'get',
        params
    })
}
// 菜单添加 
export const addMenu=(data)=>{
    return request({
        url:'/Sys/menu/save',
        method:'post',
        data
    })
}
// 菜单编辑
export const updateMenu=(data)=>{
    return request({
        url:'Sys/menu/update',
        method:'post',
        data
    })
}
// 菜单删除
export const deleteMenu=(params)=>{
    return request({
        url:'Sys/menu/delete/'+params,
        method:'post',
    })
}