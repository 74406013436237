import request from '@/utils/request'
/**
 * 上传文件
{
file:formData的文件类型
type:image表示图片，其他随意
}
 */
export async function postAddFile(params, data) {
  return request({
    url: '/Sys/file/upload',
    method: 'post',
    params,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
