import home from "./home/home.js"
// 合并路由
export default [
	...home,
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		hidden:false,
		component: () => import('@/components/404.vue'),
		meta: {
			icon:'WarningFilled',
			title: 'message.fail',
		},
	},
]