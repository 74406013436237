import Cookies from 'js-cookie'

export function getToken(token) {
  return Cookies.get(token)
}
export function setToken(key,token) {
  return Cookies.set(key, token)
}
export function removeToken(key) {
        Cookies.remove(key)
}
export function Cookieclear() {
  Cookies.clear()
}
export function GetStorage(value){
  return localStorage.getItem(value)
}
export function SetStorage(key,value){
   localStorage.setItem(key,value)
}
export function removeStorage(key){
   localStorage.removeItem(key)
}
export function localStorageClear(key){
   localStorage.clear()
}