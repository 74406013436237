<template>
  <el-config-provider :locale="locale">
    <RouterView />
  </el-config-provider>
</template>
<script>
import { RouterView } from "vue-router";
import { provide, ref, reactive, toRefs, computed } from "vue";
import { i18nStore } from "@/stores/index.js";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
import * as echarts from 'echarts'
export default {
  setup() {
    provide('echarts',echarts)
    const useI18nStore = i18nStore();
    const state = reactive({
      locale: computed(() =>
        useI18nStore.GetComlocale == "zh"
          ? zhCn
          : useI18nStore.GetComlocale == "en"
          ? en
          : ""
      ),
    });
    return {
      ...toRefs(state),
      useI18nStore,
    };
  },
};
</script>
<style lang="scss">
//
</style>
