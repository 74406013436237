import axios from "axios";
import config from "@/config";
const { LoadingList, baseURL } = config;
import { ElMessageBox,ElMessage  } from "element-plus";
import { UserInfoStore } from "@/stores/index.js";
const request = axios.create({
  changeOrigin: true,
  timeout: 5000,
  headers: {},
  baseURL: baseURL, //import.meta.env.VITE_BASE_URl
});
let loadingInstance;
let interval;
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;
const options = {
  spinner: svg,
  background: "rgba(122, 122, 122, 0.8)",
};
request.interceptors.request.use(
  (config) => {
    const useStore = UserInfoStore();
    const token = useStore.token;
    if (token) {
      config.headers["Token"] = token;
    }
    if (LoadingList.some((v) => v == config.url.includes(v))) {
      startLoading();
    }
    // console.log(config,'请求前')
    return config;
  },
  (error) => {
    // console.log(error,'请求错误')
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    endLoading();
    const useStore = UserInfoStore();
    // console.log('响应后',response)
    let res = response.data;
    if (res.code == 500) {
      ElMessage({
        message:res.msg,
        type:'error',
        plain:true,
        showClose:true,
      });
      return Promise.reject(response);
      // 非5xx的错误属于业务错误，留给具体页面处理
    } else if (res.code == 401) {
      ElMessageBox.alert(res.msg, "错误", {
        confirmButtonText: "确定",
        type: "error",
      }).then(() => {
        useStore.FedLogOut();
      });
      return Promise.reject(response);
    } else {
      return response.data;
    }
  },
  function (error) {
    // console.log(error, "响应错误")
    endLoading();
    ElMessageBox.confirm("连接出错,请联系管理员", "请求出错", {
      confirmButtonText: "确定",
      showCancelButton: false,
      type: "error",
    });
    return Promise.reject(error);
  }
);
function startLoading() {
  interval = setTimeout(() => {
    loadingInstance = ElLoading.service(options);
  }, 300);
}

function endLoading() {
  if (interval) {
    clearTimeout(interval);
    if (loadingInstance) loadingInstance.close();
  }
}
export default request;
